tinymce.addI18n('pt_BR', {
  Redo: 'Refazer',
  Undo: 'Desfazer',
  Cut: 'Recortar',
  Copy: 'Copiar',
  Paste: 'Colar',
  'Select all': 'Selecionar tudo',
  'New document': 'Novo documento',
  Ok: 'OK',
  Cancel: 'Cancelar',
  'Visual aids': 'Ajuda visual',
  Bold: 'Negrito',
  Italic: 'It\xe1lico',
  Underline: 'Sublinhado',
  Strikethrough: 'Tachado',
  Superscript: 'Sobrescrito',
  Subscript: 'Subscrito',
  'Clear formatting': 'Limpar formata\xe7\xe3o',
  Remove: 'Remover',
  'Align left': 'Alinhar \xe0 esquerda',
  'Align center': 'Centralizar',
  'Align right': 'Alinhar \xe0 direita',
  'No alignment': 'Sem alinhamento',
  Justify: 'Justificar',
  'Bullet list': 'Lista com marcadores',
  'Numbered list': 'Lista numerada',
  'Decrease indent': 'Diminuir recuo',
  'Increase indent': 'Aumentar recuo',
  Close: 'Fechar',
  Formats: 'Formata\xe7\xe3o',
  "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X/C/V keyboard shortcuts instead.":
    'O seu navegador n\xe3o suporta acesso direto \xe0 \xe1rea de transfer\xeancia. Por favor use os atalhos do teclado Ctrl+X/C/V como alternativa.',
  Headings: 'T\xedtulos',
  'Heading 1': 'T\xedtulo 1',
  'Heading 2': 'T\xedtulo 2',
  'Heading 3': 'T\xedtulo 3',
  'Heading 4': 'T\xedtulo 4',
  'Heading 5': 'T\xedtulo 5',
  'Heading 6': 'T\xedtulo 6',
  Preformatted: 'Pr\xe9-formatado',
  Div: 'Se\xe7\xe3o (div)',
  Pre: 'Pr\xe9-formatado (pre)',
  Code: 'Monoespa\xe7ada',
  Paragraph: 'Simples',
  Blockquote: 'Bloco de cita\xe7\xe3o',
  Inline: 'Fonte',
  Blocks: 'Par\xe1grafo',
  'Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.':
    'O comando Colar est\xe1 no modo de texto simples. O conte\xfado ser\xe1 colado como texto simples, at\xe9 voc\xea desligar essa op\xe7\xe3o.',
  Fonts: 'Fonte',
  'Font sizes': 'Tamanho da fonte',
  Class: 'Classe',
  'Browse for an image': 'Procurar uma imagem',
  OR: 'OU',
  'Drop an image here': 'Arraste uma imagem para c\xe1',
  Upload: 'Carregar',
  'Uploading image': 'Carregando imagem',
  Block: 'Par\xe1grafo',
  Align: 'Alinhamento',
  Default: 'Padr\xe3o',
  Circle: 'C\xedrculo',
  Disc: 'Disco',
  Square: 'Quadrado',
  'Lower Alpha': 'Letra Min\xfasc.',
  'Lower Greek': 'Grego Min\xfasc.',
  'Lower Roman': 'Romano Min\xfasc.',
  'Upper Alpha': 'Letra Mai\xfasc.',
  'Upper Roman': 'Romano Mai\xfasc.',
  'Anchor...': '\xc2ncora...',
  Anchor: '\xc2ncora',
  Name: 'Nome',
  ID: 'ID',
  'ID should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.':
    'O ID deve come\xe7ar com uma letra, seguida apenas por letras, n\xfameros, tra\xe7os, v\xedrgulas ou sublinhas.',
  'You have unsaved changes are you sure you want to navigate away?':
    'Voc\xea tem altera\xe7\xf5es n\xe3o salvas. Voc\xea tem certeza de que deseja sair?',
  'Restore last draft': 'Restaurar \xfaltimo rascunho',
  'Special character...': 'Caractere especial...',
  'Special Character': 'Caractere especial',
  'Source code': 'C\xf3digo-fonte',
  'Insert/Edit code sample': 'Inserir/editar c\xf3digo',
  Language: 'Idioma',
  'Code sample...': 'C\xf3digo...',
  'Left to right': 'Esquerda para direita',
  'Right to left': 'Direita para esquerda',
  Title: 'T\xedtulo',
  Fullscreen: 'Tela cheia',
  Action: 'A\xe7\xe3o',
  Shortcut: 'Atalho',
  Help: 'Ajuda',
  Address: 'Endere\xe7o',
  'Focus to menubar': 'Focalizar barra de menus',
  'Focus to toolbar': 'Focalizar barra de ferramentas',
  'Focus to element path': 'Focalizar caminho do elemento',
  'Focus to contextual toolbar': 'Focalizar barra de ferramentas contextual',
  'Insert link (if link plugin activated)':
    'Inserir link (se o plugin de link estiver ativado)',
  'Save (if save plugin activated)':
    'Salvar (se o plugin de salvar estiver ativado)',
  'Find (if searchreplace plugin activated)':
    'Localizar (se o plugin de localizar e substituir estiver ativado)',
  'Plugins installed ({0}):': 'Plugins instalados ({0}):',
  'Premium plugins:': 'Plugins premium:',
  'Learn more...': 'Saber mais...',
  'You are using {0}': 'Voc\xea est\xe1 usando {0}',
  Plugins: 'Plugins',
  'Handy Shortcuts': 'Atalhos \xfateis',
  'Horizontal line': 'Linha horizontal',
  'Insert/edit image': 'Inserir/editar imagem',
  'Alternative description': 'Descri\xe7\xe3o alternativa',
  Accessibility: 'Acessibilidade',
  'Image is decorative': 'A imagem \xe9 decorativa',
  Source: 'Endere\xe7o',
  Dimensions: 'Dimens\xf5es',
  'Constrain proportions': 'Restringir propor\xe7\xf5es',
  General: 'Geral',
  Advanced: 'Avan\xe7ado',
  Style: 'Estilo',
  'Vertical space': 'Espa\xe7o vertical',
  'Horizontal space': 'Espa\xe7o horizontal',
  Border: 'Borda',
  'Insert image': 'Inserir imagem',
  'Image...': 'Imagem...',
  'Image list': 'Lista de imagens',
  Resize: 'Redimensionar',
  'Insert date/time': 'Inserir data/hora',
  'Date/time': 'Data/hora',
  'Insert/edit link': 'Inserir/editar link',
  'Text to display': 'Texto a ser exibido',
  Url: 'URL',
  'Open link in...': 'Abrir link em...',
  'Current window': 'Janela atual',
  None: 'Nenhum(a)',
  'New window': 'Nova janela',
  'Open link': 'Abrir link',
  'Remove link': 'Remover link',
  Anchors: '\xc2ncoras',
  'Link...': 'Link...',
  'Paste or type a link': 'Cole ou digite um link',
  'The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?':
    'A URL que voc\xea informou parece ser um endere\xe7o de e-mail. Deseja adicionar o prefixo obrigat\xf3rio mailto:?',
  'The URL you entered seems to be an external link. Do you want to add the required http:// prefix?':
    'A URL que voc\xea informou parece ser um link externo. Deseja incluir o prefixo http://?',
  'The URL you entered seems to be an external link. Do you want to add the required https:// prefix?':
    'A URL informada parece ser um link externo. Deseja adicionar o prefixo obrigat\xf3rio https://?',
  'Link list': 'Lista de links',
  'Insert video': 'Inserir v\xeddeo',
  'Insert/edit video': 'Inserir/editar v\xeddeo',
  'Insert/edit media': 'Inserir/editar m\xeddia',
  'Alternative source': 'Endere\xe7o alternativo',
  'Alternative source URL': 'Endere\xe7o URL alternativo',
  'Media poster (Image URL)': 'Post de m\xeddia (URL da Imagem)',
  'Paste your embed code below:':
    'Insira o c\xf3digo de incorpora\xe7\xe3o abaixo:',
  Embed: 'Incorporar',
  'Media...': 'M\xeddia...',
  'Nonbreaking space': 'Espa\xe7o inquebr\xe1vel',
  'Page break': 'Quebra de p\xe1gina',
  'Paste as text': 'Colar como texto',
  Preview: 'Pr\xe9-visualizar',
  Print: 'Imprimir',
  'Print...': 'Imprimir...',
  Save: 'Salvar',
  Find: 'Localizar',
  'Replace with': 'Substituir por',
  Replace: 'Substituir',
  'Replace all': 'Substituir tudo',
  Previous: 'Anterior',
  Next: 'Pr\xf3xima',
  'Find and Replace': 'Localizar e substituir',
  'Find and replace...': 'Localizar e substituir...',
  'Could not find the specified string.':
    'N\xe3o foi poss\xedvel encontrar o termo especificado.',
  'Match case': 'Diferenciar mai\xfascula/min\xfascula',
  'Find whole words only': 'Encontrar somente palavras inteiras',
  'Find in selection': 'Localizar na sele\xe7\xe3o',
  'Insert table': 'Inserir tabela',
  'Table properties': 'Propriedades da tabela',
  'Delete table': 'Excluir tabela',
  Cell: 'C\xe9lula',
  Row: 'Linha',
  Column: 'Coluna',
  'Cell properties': 'Propriedades da c\xe9lula',
  'Merge cells': 'Agrupar c\xe9lulas',
  'Split cell': 'Dividir c\xe9lula',
  'Insert row before': 'Inserir linha antes',
  'Insert row after': 'Inserir linha depois',
  'Delete row': 'Excluir linha',
  'Row properties': 'Propriedades da linha',
  'Cut row': 'Recortar linha',
  'Cut column': 'Recortar coluna',
  'Copy row': 'Copiar linha',
  'Copy column': 'Copiar coluna',
  'Paste row before': 'Colar linha antes',
  'Paste column before': 'Colar coluna antes',
  'Paste row after': 'Colar linha depois',
  'Paste column after': 'Colar coluna depois',
  'Insert column before': 'Inserir coluna antes',
  'Insert column after': 'Inserir coluna depois',
  'Delete column': 'Excluir coluna',
  Cols: 'Colunas',
  Rows: 'Linhas',
  Width: 'Largura',
  Height: 'Altura',
  'Cell spacing': 'Espa\xe7amento da c\xe9lula',
  'Cell padding': 'Espa\xe7amento interno da c\xe9lula',
  'Row clipboard actions':
    'A\xe7\xf5es da \xe1rea de transfer\xeancia de linhas',
  'Column clipboard actions':
    'A\xe7\xf5es da \xe1rea de transfer\xeancia de colunas',
  'Table styles': 'Estilos de tabela',
  'Cell styles': 'Estilos da c\xe9lula',
  'Column header': 'Cabe\xe7alho da coluna',
  'Row header': 'Cabe\xe7alho da linha',
  'Table caption': 'Legenda da tabela',
  Caption: 'Legenda',
  'Show caption': 'Exibir legenda',
  Left: '\xc0 esquerda',
  Center: 'Centro',
  Right: '\xc0 direita',
  'Cell type': 'Tipo de c\xe9lula',
  Scope: 'Escopo',
  Alignment: 'Alinhamento',
  'Horizontal align': 'Alinhamento horizontal',
  'Vertical align': 'Alinhamento vertical',
  Top: 'Superior',
  Middle: 'Meio',
  Bottom: 'Inferior',
  'Header cell': 'C\xe9lula de cabe\xe7alho',
  'Row group': 'Grupo de linhas',
  'Column group': 'Grupo de colunas',
  'Row type': 'Tipo de linha',
  Header: 'Cabe\xe7alho',
  Body: 'Corpo',
  Footer: 'Rodap\xe9',
  'Border color': 'Cor da borda',
  Solid: 'S\xf3lida',
  Dotted: 'Pontilhada',
  Dashed: 'Tracejada',
  Double: 'Dupla',
  Groove: 'Chanfrada',
  Ridge: 'Ressaltada',
  Inset: 'Baixo relevo',
  Outset: 'Alto relevo',
  Hidden: 'Oculta',
  'Insert template...': 'Inserir modelo...',
  Templates: 'Modelos',
  Template: 'Modelo',
  'Insert Template': 'Inserir modelo',
  'Text color': 'Cor do texto',
  'Background color': 'Cor do fundo',
  'Custom...': 'Personalizado...',
  'Custom color': 'Cor personalizada',
  'No color': 'Nenhuma cor',
  'Remove color': 'Remover cor',
  'Show blocks': 'Mostrar blocos',
  'Show invisible characters': 'Exibir caracteres invis\xedveis',
  'Word count': 'Contador de palavras',
  Count: 'Contar',
  Document: 'Documento',
  Selection: 'Sele\xe7\xe3o',
  Words: 'Palavras',
  'Words: {0}': 'Palavras: {0}',
  '{0} words': '{0} palavras',
  File: 'Arquivo',
  Edit: 'Editar',
  Insert: 'Inserir',
  View: 'Visualizar',
  Format: 'Formatar',
  Table: 'Tabela',
  Tools: 'Ferramentas',
  'Powered by {0}': 'Distribu\xeddo por {0}',
  'Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help':
    '\xc1rea de texto rico. Pressione Alt+F9 para exibir o menu, Alt+F10 para exibir a barra de ferramentas ou Alt+0 para exibir a ajuda.',
  'Image title': 'T\xedtulo da imagem',
  'Border width': 'Espessura da borda',
  'Border style': 'Estilo da borda',
  Error: 'Erro',
  Warn: 'Alerta',
  Valid: 'V\xe1lido',
  'To open the popup, press Shift+Enter':
    'Para abrir o di\xe1logo, pressione Shit+Enter',
  'Rich Text Area': '\xc1rea de texto rico',
  'Rich Text Area. Press ALT-0 for help.':
    '\xc1rea de texto rico. Pressione Alt+0 para ajuda.',
  'System Font': 'Fonte do sistema',
  'Failed to upload image: {0}': 'Falha ao carregar imagem: {0}',
  'Failed to load plugin: {0} from url {1}':
    'Falha ao carregar plugin: {0} da URL {1}',
  'Failed to load plugin url: {0}': 'Falha ao carregar URL do plugin: {0}',
  'Failed to initialize plugin: {0}': 'Falha ao iniciar plugin: {0}',
  example: 'exemplo',
  Search: 'Pesquisar',
  All: 'Tudo',
  Currency: 'Moeda',
  Text: 'Texto',
  Quotations: 'Cita\xe7\xf5es',
  Mathematical: 'Matem\xe1tico',
  'Extended Latin': 'Latino estendido',
  Symbols: 'S\xedmbolos',
  Arrows: 'Setas',
  'User Defined': 'Definido pelo Usu\xe1rio',
  'dollar sign': 's\xedmbolo do d\xf3lar',
  'currency sign': 's\xedmbolo de moeda',
  'euro-currency sign': 's\xedmbolo do euro',
  'colon sign': 's\xedmbolo do colon',
  'cruzeiro sign': 's\xedmbolo do cruzeiro',
  'french franc sign': 's\xedmbolo do franco franc\xeas',
  'lira sign': 's\xedmbolo da lira',
  'mill sign': 's\xedmbolo do mill',
  'naira sign': 's\xedmbolo da naira',
  'peseta sign': 's\xedmbolo da peseta',
  'rupee sign': 's\xedmbolo da r\xfapia',
  'won sign': 's\xedmbolo do won',
  'new sheqel sign': 's\xedmbolo do novo sheqel',
  'dong sign': 's\xedmbolo do dong',
  'kip sign': 's\xedmbolo do kip',
  'tugrik sign': 's\xedmbolo do tugrik',
  'drachma sign': 's\xedmbolo do drachma',
  'german penny symbol': 's\xedmbolo de centavo alem\xe3o',
  'peso sign': 's\xedmbolo do peso',
  'guarani sign': 's\xedmbolo do guarani',
  'austral sign': 's\xedmbolo do austral',
  'hryvnia sign': 's\xedmbolo do hryvnia',
  'cedi sign': 's\xedmbolo do cedi',
  'livre tournois sign': 's\xedmbolo do livre tournois',
  'spesmilo sign': 's\xedmbolo do spesmilo',
  'tenge sign': 's\xedmbolo do tenge',
  'indian rupee sign': 's\xedmbolo de r\xfapia indiana',
  'turkish lira sign': 's\xedmbolo de lira turca',
  'nordic mark sign': 's\xedmbolo do marco n\xf3rdico',
  'manat sign': 's\xedmbolo do manat',
  'ruble sign': 's\xedmbolo do rublo',
  'yen character': 'caractere do yen',
  'yuan character': 'caractere do yuan',
  'yuan character, in hong kong and taiwan':
    'caractere do yuan, em Hong Kong e Taiwan',
  'yen/yuan character variant one': 'varia\xe7\xe3o do caractere de yen/yuan',
  Emojis: 'Emojis',
  'Emojis...': 'Emojis...',
  'Loading emojis...': 'Carregando emojis...',
  'Could not load emojis': 'N\xe3o foi poss\xedvel carregar os emojis',
  People: 'Pessoas',
  'Animals and Nature': 'Animais e Natureza',
  'Food and Drink': 'Comida e Bebida',
  Activity: 'Atividade',
  'Travel and Places': 'Viagem e Lugares',
  Objects: 'Objetos',
  Flags: 'Bandeiras',
  Characters: 'Caracteres',
  'Characters (no spaces)': 'Caracteres (sem espa\xe7os)',
  '{0} characters': '{0} caracteres',
  'Error: Form submit field collision.':
    'Erro: colis\xe3o de bot\xe3o de envio do formul\xe1rio.',
  'Error: No form element found.':
    'Erro: elemento de formul\xe1rio n\xe3o encontrado.',
  'Color swatch': 'Amostra de cor',
  'Color Picker': 'Seletor de cores',
  'Invalid hex color code: {0}':
    'C\xf3digo hexadecimal de cor inv\xe1lido: {0}',
  'Invalid input': 'Entrada inv\xe1lida',
  R: 'R',
  'Red component': 'Componente vermelho',
  G: 'G',
  'Green component': 'Componente verde',
  B: 'B',
  'Blue component': 'Componente azul',
  '#': '#',
  'Hex color code': 'C\xf3digo hexadecimal de cor',
  'Range 0 to 255': 'Faixa entre 0 e 255',
  Turquoise: 'Turquesa',
  Green: 'Verde',
  Blue: 'Azul',
  Purple: 'Roxo',
  'Navy Blue': 'Azul marinho',
  'Dark Turquoise': 'Turquesa escuro',
  'Dark Green': 'Verde escuro',
  'Medium Blue': 'Azul m\xe9dio',
  'Medium Purple': 'Roxo m\xe9dio',
  'Midnight Blue': 'Azul meia-noite',
  Yellow: 'Amarelo',
  Orange: 'Laranja',
  Red: 'Vermelho',
  'Light Gray': 'Cinza claro',
  Gray: 'Cinza',
  'Dark Yellow': 'Amarelo escuro',
  'Dark Orange': 'Laranja escuro',
  'Dark Red': 'Vermelho escuro',
  'Medium Gray': 'Cinza m\xe9dio',
  'Dark Gray': 'Cinza escuro',
  'Light Green': 'Verde claro',
  'Light Yellow': 'Amarelo claro',
  'Light Red': 'Vermelho claro',
  'Light Purple': 'Roxo claro',
  'Light Blue': 'Azul claro',
  'Dark Purple': 'Roxo escuro',
  'Dark Blue': 'Azul escuro',
  Black: 'Preto',
  White: 'Branco',
  'Switch to or from fullscreen mode': 'Abrir ou fechar modo de tela cheia',
  'Open help dialog': 'Abrir di\xe1logo de ajuda',
  history: 'hist\xf3rico',
  styles: 'estilos',
  formatting: 'formata\xe7\xe3o',
  alignment: 'alinhamento',
  indentation: 'indenta\xe7\xe3o',
  Font: 'Fonte',
  Size: 'Tamanho',
  'More...': 'Mais...',
  'Select...': 'Selecionar...',
  Preferences: 'Prefer\xeancias',
  Yes: 'Sim',
  No: 'N\xe3o',
  'Keyboard Navigation': 'Navega\xe7\xe3o pelo teclado',
  Version: 'Vers\xe3o',
  'Code view': 'Ver c\xf3digo',
  'Open popup menu for split buttons':
    'Abrir menu popup para bot\xf5es com divis\xe3o',
  'List Properties': 'Listar propriedades',
  'List properties...': 'Listar propriedades...',
  'Start list at number': 'Iniciar a lista no n\xfamero',
  'Line height': 'Altura da linha',
  'Dropped file type is not supported':
    'O tipo do arquivo arrastado n\xe3o \xe9 compat\xedvel',
  'Loading...': 'Carregando...',
  'ImageProxy HTTP error: Rejected request':
    'Erro HTTP ImageProxy: solicita\xe7\xe3o rejeitada',
  'ImageProxy HTTP error: Could not find Image Proxy':
    'Erro de HTTP ImageProxy: n\xe3o foi poss\xedvel encontrar o proxy de imagem',
  'ImageProxy HTTP error: Incorrect Image Proxy URL':
    'Erro de HTTP ImageProxy: URL de proxy de imagem incorreto',
  'ImageProxy HTTP error: Unknown ImageProxy error':
    'Erro de HTTP ImageProxy: erro ImageProxy desconhecido',
});
